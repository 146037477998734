<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">{{ $t("message.add_new_bunk") }}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="p-2" v-loading="loadingData">
      <filterSchedules ref="filter" />
      <button
          v-for="(item, index) in items"
          @click="$refs.schedule.statisticFilter(item, index)"
          class="filter-wrap__btn"
          :class="{ active: index === activeFilterIndex }"
          :key="index"
      >
        {{ $t(`message.${index}`) }}
      </button>
      <el-select
          v-model="type_id"
          :placeholder="$t(`message.patient_history_type`)"
          clearable
      >
        <el-option
            v-for="type in types"
            :key="type.id"
            :value="type.id"
            :label="type.name"
        ></el-option>
      </el-select>
      <schedulesCabinetFull
        @loadOn="loadOn()"
        @loadOff="loadOff()"
        ref="schedule"
      />
    </div>
  </div>
</template>
<script>
import filterSchedules from "./../../schedulesCabinet/components/filter-schedules";
import schedulesCabinetFull from "./../../schedulesCabinet/components/schedulesCabinetFull";
import drawerChild from "@/utils/mixins/drawer-child";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [drawerChild],
  props: {
    patient_history_type_id: {
      type: Number,
    },
  },
  components: { filterSchedules, schedulesCabinetFull },
  data() {
    return {
      loadingData: false,
      activeFilterIndex: "Day",
      activeItem: null,
      type_id: null,
      items: {
        "Day": [
          { groupBy: "Month" },
          { groupBy: "Day", format: "d" },
        ],
        "Hour": [
          { groupBy: "Month" },
          { groupBy: "Day", format: "d" },
          { groupBy: "Hour" }
        ]
      },
    };
  },
  watch: {
    type_id: {
      handler: async function (val) {
        await this.$refs.schedule.updateDataroom({patient_history_type: this.type_id});
        let type = this.types.find((item) => item.id === val);
        this.$refs.schedule.updateBusinessHours(type);
      }
    }
  },
  computed: {
    ...mapGetters({
      bunk: "schedulesCabinets/bunk",
      types: "patientHistoryTypes/list",
    }),
  },
  methods: {
    ...mapActions({
      empty: "schedulesCabinets/empty",
      delete: "schedulesCabinets/destroy",
      loadTypes: "patientHistoryTypes/index",
    }),
    afterOpened() {
      this.type_id = this.patient_history_type_id;
      // this.loadTypes({patient_history_type: this.patient_history_type_id});
      // if ()
      if (this.bunk.length != 0) {
        this.$refs.schedule.scroll();
      } else {
        this.$refs.schedule.fetchAgain();
      }
    },
    loadOn() {
      this.loadingData = true;
    },
    loadOff() {
      this.loadingData = false;
    },
    closeDel() {
      if (this.bunk.length != 0) {
        this.delete(this.bunk.id);
        this.empty();
      }
    },
    afterClosed() {
      if (this.bunk) {
        this.parent().loadBunk();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-wrap {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 16px;

  @media screen and (max-width: 1168px) {
    gap: 1rem;
    flex-wrap: wrap;
  }

  // filter-wrap__btn
  &__btn {
    outline: none;
    background: #fff;
    border: none;
    border-radius: 10px;
    padding: 6px 20px;
    color: #606266;
    font-weight: 600;
    font-size: 15px;
    transition: all 0.15s ease-in-out;

    &.active {
      color: #fff;
      background: #409eff;
    }

    &::first-letter {
      text-transform: uppercase;
    }

    &:hover {
      color: #fff;
      background: #66b1ff;
    }

    &:active {
      background: #3a8ee6;
    }
  }
}
</style>